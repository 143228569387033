import { GetBundleSaasPlan, IsSaasPlanName, GetSaasPlanByName } from "@/data/plan/saas";
import { GetBundleLifetimePlan, IsLifetimePlanName, GetLifetimePlanByName } from "@/data/plan/lifetime";
import { GetAnnuallyPlanByName, GetBundleAnnuallyPlan, IsAnnuallyPlanName } from "@/data/plan/annually";
import { BundlePlanProps, PlanProps } from "@/data/plans";

export function GetBundlePlan(type:string, country: string) : BundlePlanProps {  
    switch (type) {
        case 'lifetime':
            return GetBundleLifetimePlan(country);
        case 'annually':
            return GetBundleAnnuallyPlan(country);
        default:
            return GetBundleSaasPlan(country);
    }
}

export function FindBundlePlan(name:string, country: string) : BundlePlanProps {  
    if (IsAnnuallyPlanName(name, country) ) {
        return GetBundleAnnuallyPlan(country);
    }

    if (IsSaasPlanName(name, country) ) {
        return GetBundleSaasPlan(country);
    }

    if (IsLifetimePlanName(name, country) ) {
        return GetBundleLifetimePlan(country);
    }
    return GetBundleSaasPlan(country);
}


export function GetPlanByName(planName:string) : PlanProps | undefined {
    return  GetAnnuallyPlanByName(planName) || GetSaasPlanByName(planName) || GetLifetimePlanByName(planName);
}

export function SwitchPlanCountry(plan:PlanProps, country: string) : PlanProps | undefined {
    let plans: PlanProps[] = [];
    switch (plan.group) {
        case 'lifetime':
            plans = GetBundleLifetimePlan(country).plans;
            break;
        case 'annually':
            plans = GetBundleAnnuallyPlan(country).plans;
            break;
        default:
            plans = GetBundleSaasPlan(country).plans;
            break;
    }
    return plans.find(p => p.category === plan.category && p.interval === plan.interval);
 }