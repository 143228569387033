import { BundlePlanProps } from "@/data/plans";
import { CountryCode, TypePhoneNumber } from "@/data/regions";

export const bundlePlanUnitedStates: BundlePlanProps = {
    group: "annually",

    title: "Offer: Business phone number, 4 users included",
    plans: [
        {
            name: "stripe2024annuallyv2a",
            country: CountryCode.US,
            group: "annually",
            default: true,
            hasTrial: true,
            trialDays: 7,
    
            category: "Annually",
            title: "Phone2 One",
            subtitle: "Perfect for small and growing businesses",
            price: "$15",
            priceAmount: 18000,
            priceCurrency: "usd",
            priceDescription: "per month",
            priceDescription2: "billed annually at $180",
            interval: "year",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: $5/mo",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: $10/user/mo",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                },
                {
                    check: true,
                    title: "Unlimited sms",
                    typePhoneNumber: TypePhoneNumber.LOCAL,
                },
                {
                    check: false,
                    title: "Unlimited sms",
                    description: "SMS not supported on toll-free",
                    typePhoneNumber: TypePhoneNumber.TOLL_FREE,
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot",
                },
                { 
                    check: true, 
                    title: "Zapier" 
                },
            ],
            feeDescription: "All taxes and carrier fees included.",
            actionTitle: "Start 7-day trial",

            payButton: "Start 7-day trial",
            payDescription: "Then $180/year after trial",
        },
    ],
}