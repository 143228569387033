import { bundlePlanUnitedStates } from "@/data/plan/lifetime/us";
import { bundlePlanCanada } from "@/data/plan/lifetime/ca";
import { BundlePlanProps, PlanProps } from "@/data/plans";

export function GetBundleLifetimePlan(country: string) : BundlePlanProps {  
  switch (country) {
    case "US":
      return bundlePlanUnitedStates;
    case "CA":
      return bundlePlanCanada;
    default:
      return bundlePlanUnitedStates;
  }
}

export function IsLifetimePlanName(planName:string, country: string) : boolean {
  if (country === 'US') {
    if (bundlePlanUnitedStates.plans.find(p => p.name === planName) != null) {
      return true;
    }
  }
  if (country === 'CA') {
    if (bundlePlanCanada.plans.find(p => p.name === planName) != null) {
      return true;
    }
  }
  return false;
}


export function GetLifetimePlanByName(planName:string) : PlanProps | undefined {
  const planUS = bundlePlanUnitedStates.plans.find(p => p.name === planName)
  if (planUS != null) {
    return planUS;
  }

  const planCA = bundlePlanCanada.plans.find(p => p.name === planName)
  if (planCA != null) {
    return planCA;
  }

  return undefined
}