import React from "react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Muted } from "@/components/typography/typography";
import { Button } from "@/components/ui/button";
import Link from "next/link";
import { PlanProps } from "@/data/plans";
import { GetContentPlan } from "@/components/plan/plan-content";
import { TypePhoneNumber } from "@/data/regions";

interface PlanCardProps {
  plan: PlanProps;
}

export function PlanCard({plan}: PlanCardProps) {
  const hashedPlan = btoa(plan.name);
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-2xl">{plan.title}</CardTitle>
        <CardDescription>
          {plan.subtitle}
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col w-full space-y-0">
        <GetContentPlan plan={plan}  typePhoneNumber={TypePhoneNumber.LOCAL} />
      </CardContent>
      <CardFooter>
        <div className="w-full">
            {plan.feeDescription && <Muted className="w-full">{plan.feeDescription}</Muted>}
          <div className="mt-6">
            <Link href={`search/?c=${plan.country}&p=${hashedPlan}`}>
              <Button className="w-full min-h-12">{plan.actionTitle}</Button>
            </Link>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
