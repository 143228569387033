"use client";

import { useSearchParams } from "next/navigation";
import { TitleView } from "@/components/title-view";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { PlanCard } from "@/components/plan-card";
import { GetBundlePlan, GetPlanByName } from "@/data/plan/bundle";
import { BundlePlanProps } from "@/data/plans";
import SafeArea from "@/components/safe-area";
import { ConvertCountry } from "@/data/regions";
import { setSearchParam, decodeUrl } from "@/lib/window";
import { useState } from "react";

export default function Content() {  
  const searchParams = useSearchParams();
  const country = ConvertCountry(searchParams?.get('c') || 'US');
  const hashedPlan = decodeUrl(searchParams?.get('p') || '') || '';
  const planName = atob(hashedPlan);
  const group = (planName.length > 0 ? GetPlanByName(planName)?.group : searchParams?.get('tp') || null) || 'saas' 
  
  const bundle: BundlePlanProps = GetBundlePlan(group, country);
  const [plan, setPlan] = useState(planName || bundle.plans.find((e) => e.default === true)?.name || '');

  const handleTabChange = (newValue: string) => {
    setPlan(newValue);

    // Update history
    setSearchParam('p', btoa(newValue));
  };

  return (
    <>
      <main className="container mx-auto max-w-4xl mb-12" style={{ flex: "1" }}>
        <SafeArea>
          <TitleView
            title={bundle.title}
            description={bundle.description}
          />
          <div 
            className="flex justify-center space-y-0 px-3"
            >
            <Tabs 
              style={{ flex: "1", maxWidth: "400px" }}
              defaultValue={plan} 
              onValueChange={(newValue) => handleTabChange(newValue)}
              >
              {bundle.plans.length > 1 &&
              <TabsList 
                className="grid w-full grid-cols-2 min-h-12"
                >
                {bundle.plans.map((plan, index) => (
                  <TabsTrigger className="min-h-10" key={index} value={plan.name}>{plan.category}</TabsTrigger>
                ))}
              </TabsList>}
              {bundle.plans.map((plan, index) => (
                <TabsContent 
                  key={index}
                  value={plan.name}
                  >
                  <PlanCard plan={plan}/>
                </TabsContent>
              ))}
            </Tabs>
          </div>
        </SafeArea>
      </main>
      {/* <ReviewView /> */}
    </>
  );
};