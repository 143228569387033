import { BundlePlanProps } from "@/data/plans";
import { CountryCode, TypePhoneNumber } from "@/data/regions";

export const bundlePlanCanada: BundlePlanProps = {
    group: "annually",

    title: "Order Virtual Phone Number For Your Canada Business 🇨🇦",
    plans: [
        {
            name: "stripe2024canadaannuallyv2a",
            country: CountryCode.CA,
            group: "annually",
            default: true,
            hasTrial: true,
            trialDays: 7,
    
            category: "Annually",
            title: "Phone2 One",
            subtitle: "Perfect for small and growing businesses",
            price: "CA$20",
            priceAmount: 2000,
            priceCurrency: "cad",
            priceDescription: "per month",
            priceDescription2: "billed annually at CA$240",
            interval: "year",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: CA$5/mo",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: CA$10/user/mo",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    description: "any US/Canadian phone numbers",
                },
                {
                    check: true,
                    title: "Unlimited sms",
                    description: "to any Canada phone numbers",
                    typePhoneNumber: TypePhoneNumber.LOCAL,
                },
                {
                    check: false,
                    title: "Unlimited sms",
                    description: "SMS not supported on toll-free",
                    typePhoneNumber: TypePhoneNumber.TOLL_FREE,
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot of each user in your Phone2 workspace",
                },
                { 
                    check: true, 
                    title: "Zapier" 
                },
            ],
            feeDescription: "All taxes and carrier fees included.",
            actionTitle: "Start 7-day trial",

            payButton: "Start 7-day trial",
            payDescription: "Then CA$200/year after trial",
        },
    ],
}